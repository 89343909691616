'use client'
import { useEffect } from 'react'
import { dispatchRollbarError } from '@utilities/helpers/dispatchRollbarError.helpers'
// This file will only be shown in a production env
// In a development env, the error UI will be displayed

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    dispatchRollbarError(`${error?.message} - Digest: ${error?.digest}`)
  }, [])

  return (
    <html>
      <body>
        <div
          style={{
            backgroundColor: '#f7f1e4',
            fontFamily:
              '"Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            height: '98vh',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 24,
          }}>
          <h1 style={{ color: '#b32e37' }}>Oops!</h1>
          {typeof error?.message === 'string' && (
            <p style={{ maxWidth: '50%', fontWeight: 400 }}>{error.message}</p>
          )}
        </div>
      </body>
    </html>
  )
}
