'use client'
// All error.tsx components are client components.
// Server components errors are passed as props to these client components.
// So we can use hooks
import { isServer } from './is-server'

export const dispatchRollbarError = async (
  errorMessage: string,
  token?: string,
  rollbar_env?: string
) => {
  if (!errorMessage) errorMessage = 'Unknown error'

  const Rollbar = (await import('rollbar')).default

  const req = null
  if (isServer) {
    const rollbar = new Rollbar({
      accessToken: process.env.ROLLBAR_SERVER_TOKEN,
      environment: process.env.ROLLBAR_ENVIRONMENT,
    })
    rollbar.error(errorMessage, req, rollbarError => {
      if (rollbarError) {
        console.warn('Rollbar error reporting failed:')
        console.warn(rollbarError)
        return
      }
      console.log('Reported error to Rollbar: ', errorMessage)
    })
  } else {
    const rollbar = new Rollbar({
      accessToken: token,
      environment: rollbar_env,
    })

    rollbar.error(errorMessage, req, rollbarError => {
      if (rollbarError) {
        console.warn('Rollbar error reporting failed:')
        console.warn(rollbarError)
        return
      }
      console.log('Reported error to Rollbar')
    })
  }
}
